var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("setup-table", {
        attrs: {
          title: _vm.showHidden ? "Hidden Location" : "Location",
          items: _vm.items,
          headers: _vm.headers,
          loading: _vm.loading,
          clearItem: !_vm.showForm || !_vm.editedItem.id,
          showSearch: "",
          pagination: "",
          "data-cy": "setup-table-location",
        },
        on: {
          createItem: function ($event) {
            return _vm.createItem()
          },
          editItem: _vm.editItem,
          deleteItems: _vm.deleteItems,
        },
        scopedSlots: _vm._u(
          [
            _vm.showForm
              ? {
                  key: "itemForm",
                  fn: function () {
                    return [
                      _c("location-form", {
                        ref: "locationForm",
                        attrs: {
                          value: _vm.editedItem,
                          valueRoles: _vm.valueRoles,
                          vehicleLocations: _vm.vehicleLocations,
                          zoneItems: _vm.zoneItems,
                          users: _vm.users,
                        },
                        on: {
                          submit: _vm.saveItem,
                          closeForm: _vm.closeForm,
                          formChanges: _vm.formChanges,
                        },
                      }),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: `item.synced`,
              fn: function ({ item }) {
                return [
                  item.synced
                    ? _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-sync"),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.name`,
              fn: function ({ item }) {
                return [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(_vm._s(`${item.name} (${item.abbr})`)),
                  ]),
                ]
              },
            },
            {
              key: `item.type`,
              fn: function ({ item }) {
                return [
                  item.schoolId
                    ? _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-school"),
                      ])
                    : _vm._e(),
                  item.depotId
                    ? _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-bus"),
                      ])
                    : _vm._e(),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c("location-manager", {
        ref: "slaManager",
        on: { refresh: _vm.fetchItems },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }