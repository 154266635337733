var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    { attrs: { readonly: _vm.value.id > 0 && !_vm.canEditSetupTables } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.cancel(false)
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _vm.canEditSetupTables
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "float-right",
                          attrs: {
                            color: "green",
                            "data-cy": "location-save-btn",
                            dark: "",
                          },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v(" save ")]
                      )
                    : _vm._e(),
                  _vm.me.is.superAdmin && _vm.value.synced
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "float-right mr-2",
                          attrs: {
                            color: "primary",
                            disabled: _vm.value.cannotDelete,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.hide()
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-2", attrs: { small: "" } },
                            [
                              _vm._v(
                                "mdi-" +
                                  _vm._s(_vm.value.hidden ? "eye" : "eye-off")
                              ),
                            ]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(_vm.value.hidden ? "Unhide" : "Hide") +
                              " "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        [
          _vm.value.synced
            ? _c(
                "v-alert",
                { attrs: { outlined: "", type: "info", text: "" } },
                [
                  _vm._v(
                    " This Location is synced with a School and/or Depot Stop in EZRouting. Some fields will not be able to be modified here. "
                  ),
                ]
              )
            : !_vm.value.id && _vm.isRoutingClient
            ? _c(
                "v-alert",
                { attrs: { outlined: "", type: "info", text: "" } },
                [
                  _vm._v(
                    " This Location will only be available in EZActivityTrips. If you would like this School or Depot Stop to be available in EZRouting, please add them there first and they will be synced with EZActivityTrips. "
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "v-expansion-panels",
            { attrs: { accordion: "", mandatory: "" } },
            [
              _c(
                "v-expansion-panel",
                [
                  _c(
                    "v-expansion-panel-header",
                    {
                      attrs: {
                        "data-cy": "location-panel",
                        "data-panel-name": "general",
                      },
                    },
                    [_vm._v("General")]
                  ),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm.value.code,
                                  label: "Code",
                                  required: "",
                                  readonly:
                                    _vm.value.synced && !!_vm.value.isSchool,
                                  "error-messages": _vm.handleErrors(
                                    _vm.$v.form.code
                                  ),
                                  "data-cy": "location-code",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.$v.form.code.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.code.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.code,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "$v.form.code.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm.value.name,
                                  label: "Name",
                                  required: "",
                                  readonly: _vm.value.synced,
                                  "error-messages": _vm.handleErrors(
                                    _vm.$v.form.name
                                  ),
                                  "data-cy": "location-name",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.$v.form.name.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.name.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.name,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "$v.form.name.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm.value.abbr,
                                  label: "Abbreviation",
                                  required: "",
                                  "error-messages": _vm.handleErrors(
                                    _vm.$v.form.abbr
                                  ),
                                  "data-cy": "location-abbr",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.$v.form.abbr.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.abbr.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.abbr,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "$v.form.abbr.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "2" } },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  value: _vm.value.active,
                                  readonly: _vm.value.synced,
                                  label: "Active",
                                  required: "",
                                  "data-cy": "location-active-checkbox",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$v.form.active.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.active.$model,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.$v.form.active, "$model", $$v)
                                  },
                                  expression: "$v.form.active.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "2" } },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  value: _vm.value.isSchool,
                                  readonly: _vm.value.synced,
                                  label: "School",
                                  required: "",
                                  "data-cy": "location-school-checkbox",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$v.form.isSchool.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.isSchool.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.isSchool,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "$v.form.isSchool.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "3" } },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  value: _vm.value.isVehicleLocation,
                                  label: "Vehicle Location",
                                  "true-value": 1,
                                  "false-value": 0,
                                  readonly: _vm.value.synced,
                                  required: "",
                                  "data-cy":
                                    "location-vehicle-location-checkbox",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$v.form.isVehicleLocation.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.isVehicleLocation.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.isVehicleLocation,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.form.isVehicleLocation.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "5" } },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  value: _vm.value.inclCommonDest,
                                  label: "Include in Common Destinations",
                                  required: "",
                                  "data-cy":
                                    "location-common-destination-checkbox",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$v.form.inclCommonDest.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.inclCommonDest.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.inclCommonDest,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "$v.form.inclCommonDest.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "3" } },
                            [
                              _c("v-combobox", {
                                attrs: {
                                  value: _vm.value.zone,
                                  items: _vm.zoneItems,
                                  label: "Zone",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.$v.form.zone.$touch()
                                  },
                                },
                                nativeOn: {
                                  input: function ($event) {
                                    _vm.$v.form.zone.$model =
                                      $event.srcElement.value
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.zone.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.zone,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "$v.form.zone.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "mb-0 pb-0",
                              attrs: { cols: "12", md: "12" },
                            },
                            [
                              _c("v-select", {
                                staticClass: "mb-0",
                                attrs: {
                                  label: "Vehicle Order of Assignment",
                                  value: _vm.value.vehicleOrder,
                                  items: _vm.vehicleLocations,
                                  "item-text": "name",
                                  "item-value": "id",
                                  outlined: "",
                                  multiple: "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.limiter(
                                      $event,
                                      4,
                                      "Vehicle Locations"
                                    )
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selection",
                                    fn: function ({ item, parent }) {
                                      return [
                                        _c(
                                          "v-chip",
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "pr-2" },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.name) + " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-icon",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return parent.selectItem(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" $delete ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.$v.form.vehicleOrder.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.vehicleOrder,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "$v.form.vehicleOrder.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c(
                    "v-expansion-panel-header",
                    {
                      class: { "red--text": _vm.isAddressFormInvalid() },
                      attrs: {
                        "data-cy": "location-panel",
                        "data-panel-name": "address",
                      },
                    },
                    [_vm._v("Address")]
                  ),
                  _c(
                    "v-expansion-panel-content",
                    { attrs: { eager: "" } },
                    [
                      _c("destination-autocomplete", {
                        attrs: {
                          loading: _vm.loadingLocations,
                          includeDestinations: false,
                          searchInput: _vm.searchInput,
                          placeholder:
                            "Begin typing to search for a location...",
                          "radio-label": "Search new locations by:",
                          hint: "Use this field to prefill the form below, or complete the form manually",
                          outlined: "",
                          "persistent-hint": "",
                          "data-cy": "location-address-search",
                        },
                        on: {
                          "update:searchInput": function ($event) {
                            _vm.searchInput = $event
                          },
                          "update:search-input": function ($event) {
                            _vm.searchInput = $event
                          },
                          destinationChanged: _vm.handleNewLocation,
                          internalLoading: (val) =>
                            (_vm.loadingLocations = val),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function ({ on, attrs, item }) {
                              return [
                                _c(
                                  "v-list-item",
                                  _vm._g(
                                    _vm._b({}, "v-list-item", attrs, false),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "orange", left: "" } },
                                      [_vm._v("mdi-map-marker-plus")]
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(item.text)),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("v-divider", { staticClass: "my-6" }),
                      _c(
                        "v-row",
                        { staticClass: "pt-2" },
                        [
                          _c("address-form", {
                            ref: "addressForm",
                            attrs: {
                              readonly: _vm.value.synced,
                              multiple: false,
                              showLatLong: true,
                              required: "",
                            },
                            model: {
                              value: _vm.$v.form.address.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.address, "$model", $$v)
                              },
                              expression: "$v.form.address.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c(
                    "v-expansion-panel-header",
                    {
                      attrs: {
                        "data-cy": "location-panel",
                        "data-panel-name": "site-level-authority",
                      },
                    },
                    [_vm._v("Site Level Authority")]
                  ),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "mb-0 pb-0",
                              attrs: { cols: "12", md: "12" },
                            },
                            [
                              _c("user-select", {
                                attrs: {
                                  value: _vm.locationRoles.siteAdmins,
                                  users: _vm.users,
                                  label: "Site Admins",
                                  saveEmail: "",
                                  multiple: true,
                                },
                                on: {
                                  handleUserSelect: function ($event) {
                                    return _vm.handleRoleAssignment(
                                      $event,
                                      "siteAdmins"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.locationRoles.siteAdmins,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.locationRoles,
                                      "siteAdmins",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "locationRoles.siteAdmins",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "mb-0 pb-0",
                              attrs: { cols: "12", md: "12" },
                            },
                            [
                              _c("user-select", {
                                attrs: {
                                  value: _vm.locationRoles.siteAdminsReadOnly,
                                  users: _vm.users,
                                  label: "Site Admins - Read Only",
                                  saveEmail: "",
                                  multiple: true,
                                },
                                on: {
                                  handleUserSelect: function ($event) {
                                    return _vm.handleRoleAssignment(
                                      $event,
                                      "siteAdminsReadOnly"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.locationRoles.siteAdminsReadOnly,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.locationRoles,
                                      "siteAdminsReadOnly",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "locationRoles.siteAdminsReadOnly",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "mb-0 pb-0",
                              attrs: { cols: "12", md: "12" },
                            },
                            [
                              _c("user-select", {
                                attrs: {
                                  value:
                                    _vm.locationRoles.siteAdminsReportsOnly,
                                  users: _vm.users,
                                  label: "Site Admins - Reports Only",
                                  saveEmail: "",
                                  multiple: true,
                                },
                                on: {
                                  handleUserSelect: function ($event) {
                                    return _vm.handleRoleAssignment(
                                      $event,
                                      "siteAdminsReportsOnly"
                                    )
                                  },
                                },
                                model: {
                                  value:
                                    _vm.locationRoles.siteAdminsReportsOnly,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.locationRoles,
                                      "siteAdminsReportsOnly",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "locationRoles.siteAdminsReportsOnly",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "mb-0 pb-0",
                              attrs: { cols: "12", md: "12" },
                            },
                            [
                              _c("user-select", {
                                attrs: {
                                  value: _vm.locationRoles.paysInvoices,
                                  users: _vm.users,
                                  label: "Pays Invoices",
                                  noChips: "",
                                  saveEmail: "",
                                },
                                on: {
                                  handleUserSelect: function ($event) {
                                    return _vm.handleRoleAssignment(
                                      $event,
                                      "paysInvoices"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.locationRoles.paysInvoices,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.locationRoles,
                                      "paysInvoices",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "locationRoles.paysInvoices",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "mb-0 pb-0",
                              attrs: { cols: "12", md: "12" },
                            },
                            [
                              _c("user-select", {
                                attrs: {
                                  value: _vm.locationRoles.vehicleOwner,
                                  users: _vm.users,
                                  label: "Schedules Vehicles (Owner)",
                                  noChips: "",
                                  saveEmail: "",
                                },
                                on: {
                                  handleUserSelect: function ($event) {
                                    return _vm.handleRoleAssignment(
                                      $event,
                                      "vehicleOwner"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.locationRoles.vehicleOwner,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.locationRoles,
                                      "vehicleOwner",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "locationRoles.vehicleOwner",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "mb-0 pb-0",
                              attrs: { cols: "12", md: "12" },
                            },
                            [
                              _c("user-select", {
                                attrs: {
                                  value:
                                    _vm.locationRoles.specialNeedsVehicleOwner,
                                  users: _vm.users,
                                  label: "Schedules Special Needs Vehicles",
                                  noChips: "",
                                  saveEmail: "",
                                },
                                on: {
                                  handleUserSelect: function ($event) {
                                    return _vm.handleRoleAssignment(
                                      $event,
                                      "specialNeedsVehicleOwner"
                                    )
                                  },
                                },
                                model: {
                                  value:
                                    _vm.locationRoles.specialNeedsVehicleOwner,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.locationRoles,
                                      "specialNeedsVehicleOwner",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "locationRoles.specialNeedsVehicleOwner",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "mb-0 pb-0",
                              attrs: { cols: "12", md: "12" },
                            },
                            [
                              _c("user-select", {
                                attrs: {
                                  value: _vm.locationRoles.nurses,
                                  users: _vm.users,
                                  label: "School Nurse",
                                  saveEmail: "",
                                  noChips: "",
                                },
                                on: {
                                  handleUserSelect: function ($event) {
                                    return _vm.handleRoleAssignment(
                                      $event,
                                      "nurses"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.locationRoles.nurses,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.locationRoles,
                                      "nurses",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "locationRoles.nurses",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "mb-0 pb-0",
                              attrs: { cols: "12", md: "12" },
                            },
                            [
                              _c("user-select", {
                                attrs: {
                                  value: _vm.locationRoles.nutritionists,
                                  users: _vm.users,
                                  label:
                                    "Child Nutrition Notification Lunch Trips",
                                  saveEmail: "",
                                  noChips: "",
                                },
                                on: {
                                  handleUserSelect: function ($event) {
                                    return _vm.handleRoleAssignment(
                                      $event,
                                      "nutritionists"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.locationRoles.nutritionists,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.locationRoles,
                                      "nutritionists",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "locationRoles.nutritionists",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c(
                    "v-expansion-panel-header",
                    {
                      attrs: {
                        "data-cy": "location-panel",
                        "data-panel-name": "site-level-approvers",
                      },
                    },
                    [_vm._v("Site Level Approvers")]
                  ),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("v-data-table", {
                        staticClass: "elevation-1 fixed-table",
                        attrs: {
                          headers: _vm.approverHeaders,
                          items: _vm.approverItems,
                          dense: "",
                          "hide-default-footer": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: `item.level`,
                              fn: function ({ item }) {
                                return [
                                  _c("strong", { staticClass: "mb-0" }, [
                                    _vm._v(_vm._s(item.level)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }